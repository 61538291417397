<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <div class="d-flex flex-wrap"></div>

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="5" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <b-button :variant="'primary'" class="mb-1" @click="openCreateRecord">
              <span class="d-none d-sm-inline">Create new record</span>
              <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input @keyup="checkString($event)" v-model="searchQuery" class="d-inline-block mr-1"
                placeholder="Search..." :class="{ red_border: notEmpty }" />
            </div>
          </b-col>
          <b-col cols="12" md="1">
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary" @click="masterSearch()">
                Filter
              </button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table ref="refUserListTable" class="position-relative" responsive primary-key="id" :items="listRecords"
        empty-text="No records found" :fields="fields" :filter="search" :per-page="0" id="my-table"
        :current-page="currentPage" show-empty>
        <template v-slot:cell(base_each_way)="{ item }">
          {{ "$" + item.base_each_way }}
        </template>

        <template v-slot:cell(mileage)="{ item }">
          {{ "$" + item.mileage }}
        </template>

        <template v-slot:cell(wait_time_per_hour)="{ item }">
          {{ "$" + item.wait_time_per_hour }}
        </template>

        <template v-slot:cell(additional_stop)="{ item }">
          {{ "$" + item.additional_stop }}
        </template>

        <template v-slot:cell(cancellation)="{ item }">
          {{ "$" + item.cancellation }}
        </template>

        <template v-slot:cell(minimum)="{ item }">
          {{ "$" + item.minimum }}
        </template>
        <template v-slot:cell(from_miles)="{ item }">
          {{ item.from_miles }}
        </template>
        <template v-slot:cell(to_miles)="{ item }">
          {{ item.to_miles }}
        </template>


        <!-- Column: Actions -->
        <template #cell(actions)="{ item }">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL" transition="scale-transition"
            :offset-y="true">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <template style="padding: 0" v-slot:activator="{ on, attrs }">
              <b-btn color="primary" v-bind="attrs" v-on="on" icon ripple>
              </b-btn>
            </template>
            <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
              <router-link class="urlPagina" :to="{
              name: 'form-prices-corporate-account',
              params: {
                id: item.id,
                item: item,
                listCompanies: listCompanies,
              },
            }">
                <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                  <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px">
                    <feather-icon icon="FileTextIcon" />
                    Details
                  </b-list-group-item>
                </b-list-group-item>
              </router-link>
            </b-list-group>
            <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
              <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false"
                v-if="$hasPermission('delete-records')">
                <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px"
                  @click="deleteRecord(item.id)">
                  <feather-icon icon="TrashIcon" />
                  Delete
                </b-list-group-item>
              </b-list-group-item>
            </b-list-group>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of
              {{ totalItems }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <div class="pagination_block">
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                  <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
                    <button type="button" class="page-link" @click="getRecords(pagination.first_page_url)">
                      &laquo;
                    </button>
                  </li>
                  <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
                    <button type="button" class="page-link" @click="getRecords(pagination.prev_page_url)">
                      Previous
                    </button>
                  </li>
                  <li class="page-item" v-if="currentPage - 2 > 0">
                    <button type="button" class="page-link" @click="
              getRecords(
                pagination.path + '?page=' + (currentPage - 2)
              )
              ">
                      {{ currentPage - 2 }}
                    </button>
                  </li>
                  <li class="page-item" v-if="pagination.prev_page_url">
                    <button type="button" class="page-link" @click="getRecords(pagination.prev_page_url)">
                      {{ currentPage - 1 }}
                    </button>
                  </li>
                  <li class="page-item">
                    <button type="button" class="page-link" :class="{
              current: pagination.current_page == currentPage,
            }">
                      {{ currentPage }}
                    </button>
                  </li>
                  <li class="page-item" v-if="pagination.next_page_url">
                    <button type="button" class="page-link" @click="getRecords(pagination.next_page_url)">
                      {{ currentPage + 1 }}
                    </button>
                  </li>
                  <li class="page-item" v-if="currentPage + 2 <= lastPage">
                    <button type="button" class="page-link" @click="
              getRecords(
                pagination.path + '?page=' + (currentPage + 2)
              )
              ">
                      {{ currentPage + 2 }}
                    </button>
                  </li>
                  <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
                    <button type="button" class="page-link" @click="getRecords(pagination.next_page_url)">
                      Next
                    </button>
                  </li>
                  <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
                    <button type="button" class="page-link" @click="getRecords(pagination.last_page_url)">
                      &raquo;
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BListGroup,
  BListGroupItem,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    vSelect,
  },
  name: "ListPrices",
  watch: {
    "$route.params.ca_id"() {
      this.$swal({
        title: "Loading data...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      sessionStorage.setItem("ca_idCompanyPrices", null);    
      this.loadInformation();
    }
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [3, 5, 10],
      currentPage: 1,
      listRecords: [],
      search: "",
      fields: [
        { label: "Corp Acc", key: "corporate_account.company_legal_name" },
        "type_service",
        { label: "Base Each Way Fee", key: "base_each_way" },
        { label: "Price per mile", key: "mileage" },
        { label: "Wait Time Per Hour Fee", key: "wait_time_per_hour" },
        { label: "Additional stop fee", key: "additional_stop" },
        { label: "Cancellation fee", key: "cancellation" },
        { label: "Minimum fee", key: "cancellation" },
        { label: "Minimum miles fee", key: "minimum_miles" },
        { label: "From miles", key: "from_miles" },
        { label: "To miles", key: "to_miles" },
        "actions",
      ],
      listCompanies: [],
      totalItems: 0,
      pagination: {},
      lastPage: 0,
      searchQuery: "",
      notEmpty: false,
      ca_id: 0,
    };
  },
  methods: {
    checkString(e) {
      if (this.searchQuery == "") {
        this.ca_id=null;
        this.$route.params.ca_id=null;
        sessionStorage.setItem("ca_idCompanyPrices", null); 
        
        this.getRecords();
      }
      if (e.keyCode == 13) {
        this.masterSearch();
      }
      this.notEmpty = false;
    },
    masterSearch(url = "") {
console.log("masterSearch")
        this.setValuesFilter();
        this.$swal({
          title: "Please, wait...",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        if (url == "") {
          url =
            `ca/panel/getall_price_corporate_account/search/` +
            this.searchQuery;
        }
        if (this.searchQuery == "" || this.searchQuery==null) {
        url = `ca/panel/getall_price_corporate_account`;
      }

        this.$http
          .get(url)
          .then((response) => {
            this.listRecords = response.data.data.data;
            this.totalItems = response.data.data.total;
            this.currentPage = response.data.data.current_page;
            this.lastPage = response.data.data.last_page;
            this.pagination = response.data.data;
            this.perPage = response.data.data.per_page;

            this.$swal.close();
          })
          .catch((res) => console.log(res.data));
      
    },
    getRecords(url = "") {
      console.log("getrecords")
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      if (url == "") {
        url = `ca/panel/getall_price_corporate_account`;
      }
      //if rol is superadmin

      this.ca_id = this.$route.params.ca_id;
let afd=this.ca_id;
      if (this.ca_id == null) {
        this.getCurrentValuesFilter()
      }
      //if rol is corporation
      if (this.ca_id != null) {
        url = `ca/${this.ca_id}/panel/getallByIdCA_price_corporate_account/1`;
        let afd=434;

      }
      if (this.$store.getters["Users/userData"].user.role.id === 3) {
        this.ca_id =
          this.$store.getters["Users/userData"].user.corporate_account.id;
        url = `ca/${this.ca_id}/panel/getallByIdCA_price_corporate_account`;
        let afds=434;
      }

      this.setValuesFilter();

      this.$http
        .get(url)
        .then((response) => {
          this.listRecords = response.data.data.data;
          this.totalItems = response.data.data.total;
          this.currentPage = response.data.data.current_page;
          this.lastPage = response.data.data.last_page;
          this.pagination = response.data.data;
          this.perPage = response.data.data.per_page;
          this.$swal.close();
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            title: error.response.data.message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    deleteRecord(id) {
      this.$swal({
        title: "Do you want delete this record?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        buttonsStyling: true,
      }).then((result) => {
        if (result.value === true) {
          this.$swal({
            title: "Please, wait...",
            didOpen: () => {
              this.$swal.showLoading();
            },
          });

          this.$http
            .post(`ca/panel/corporate/price_corporate_account/${id}/delete`)
            .then((res) => {
              this.getRecords();
              this.$swal({
                title: res.data.data,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push({ name: "all-selfpaids" });
                }
              });
            })
            .catch((error) => {
              console.log(error);
              this.$swal({
                title: error.response.data.message,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    getCompanies() {
      let url = "ca/panel/ca/list";

      this.$http
        .get(url)
        .then((response) => {
          this.listCompanies = response.data.data;
          this.$swal.close();
        })
        .catch((res) => console.log(res.data));
    },
    openCreateRecord() {
      this.$router.push({ name: "form-prices-corporate-account" });
    },
    setValuesFilter() {
      sessionStorage.setItem("searchQueryCompanyPrices", JSON.stringify(this.searchQuery));
      sessionStorage.setItem("ca_idCompanyPrices", JSON.stringify(this.ca_id));
    },
    getCurrentValuesFilter() {
      this.searchQuery = JSON.parse(sessionStorage.getItem("searchQueryCompanyPrices"));
      this.ca_id = JSON.parse(sessionStorage.getItem("ca_idCompanyPrices"));
    },
    loadInformation() {
      this.getCurrentValuesFilter();
      if (this.searchQuery) {
        this.masterSearch();
      } else {
        this.getCompanies();
        this.getRecords();
      }
    },
  },
  computed: {
    rows() {
      return this.listRecords.length;
    },
  },
  mounted() {
    this.loadInformation();

  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.urlPagina {
  text-decoration: none;
  color: #7367f0;
}

.urlPagina:hover {
  background: linear-gradient(118deg,
      #7367f0,
      rgba(115, 103, 240, 0.7)) !important;
  color: #fff;
}

.list-group-item:hover {
  background: linear-gradient(118deg,
      #7367f0,
      rgba(115, 103, 240, 0.7)) !important;
  color: #fff !important;
}

.urlPagina::before {
  background-color: currentColor !important;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.box {
  box-shadow: 0 14px 20px 0 rgba(143, 143, 143, 0.2) !important;
}

.current {
  background: #7367f0;
  color: white;
}

.red_border {
  border: 1px solid red;
  border-radius: 6px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
